import styled from "styled-components";
import NextLink from "next/link";
import {
  Text,
  Box,
  IconFa,
  type ThemeType,
  Link,
} from "@cruk/cruk-react-components";
import { faSterlingSign } from "@fortawesome/free-solid-svg-icons";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { isPast } from "@fwa/src/utils/timeUtils";
import { userNameForPage } from "@fwa/src/utils/pageUtils";

import { MemberFundraisingPage } from "@fwa/src/components/MemberFundraisingPage";

import { type FundraisingPageType } from "@fwa/src/types";

const Closed = styled(Text)`
  line-height: 3rem;
`;

const ResponsiveAlign = styled.div<{ theme: ThemeType }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    justify-content: flex-end;
  }
`;

export const MemberFundraisingPageDonate = ({
  fundraisingPage,
  isLeader = false,
}: {
  fundraisingPage: FundraisingPageType;
  isLeader?: boolean;
}) => {
  const name = userNameForPage(fundraisingPage);
  const { trackEventGtm } = useTracking();
  const { closeDate } = fundraisingPage;
  const hasPageClosed = closeDate && isPast(new Date(closeDate));

  if (!fundraisingPage) return null;

  return (
    <MemberFundraisingPage
      fundraisingPage={fundraisingPage}
      isLeader={isLeader}
      data-component="member-fundraising-page-overview"
    >
      <Box paddingTop="xs" margin="none">
        {hasPageClosed ? (
          <ResponsiveAlign>
            <Closed textWeight={700} textAlign="center">
              Page Closed
            </Closed>
          </ResponsiveAlign>
        ) : (
          <ResponsiveAlign>
            <NextLink
              href={`/donate/${fundraisingPage?.uniqueId}/details`}
              aria-label={`Donate to ${name}`}
              onClick={() => {
                trackEventGtm({ event: "donate_cta", cta: "member" });
              }}
              data-cta-type="donate"
            >
              <Link appearance="secondary" as="span">
                <Box as="span" marginRight="xxs">
                  <IconFa faIcon={faSterlingSign} />
                </Box>
                Donate
              </Link>
            </NextLink>
          </ResponsiveAlign>
        )}
      </Box>
    </MemberFundraisingPage>
  );
};

export default MemberFundraisingPageDonate;
